:root {
  --primary: #764aab;

  --primaryGradient: linear-gradient(94.06deg, #4CAF4F -1.21%, #6BBD6D 50.75%, #358437 102.71%);
  --secondary: #0f1b24;
  --white: #ffffff;
  --lightScrollbar: #764aab;
}
@font-face {
  font-family: 'Adiana';
  src: url('/src/app/assets/fonts/Adigiana\ Extreme.ttf') format('opentype');  
  
  font-weight: 'normal';
  font-style: 'normal';
}
@font-face {
  font-family: 'Arco';
  src: url('/src/app/assets/fonts/arco-cyrillic.otf') format('opentype');  
  
  font-weight: 'normal';
  font-style: 'normal';
}
.para-text  {
  font-family: 'Adiana', sans-serif !important;
}

p{
font-family: 'Adiana', sans-serif !important;
}
.heading-font{
  font-family: 'Arco', sans-serif !important;
}

::selection {
  background: var(--primary);
  color: var(--white);
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  padding: 0px 4px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--lightScrollbar);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  color: black !important;

}
.countBOx {
  display: flex;
  justify-content: center;
}
.mb-2 {
margin-bottom: 1rem;
}
.inputcount {
  width: 3rem;
  padding: 0.6rem 0;
  border: 1px solid #ddd;
  color: #000;
  font-size: 1.1rem;
  padding-left: 1rem;
}
.totalprize {
list-style: none;
padding: 0;
margin: 0;
}
.totalprize li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.totalprize h5 {
  font-size: 1.1rem;
  margin: 0;
}
.totalprize b {
  color: #8F52A1;
  font-size: 1.2rem;
}
.text-color{
  color: black !important;
}
@media only screen and (min-width: 1350px) {
 .footer-img{
  background-position-y: 70%;
 }
}

@media only screen and (max-width: 800px) {
  .product-heading-img{
   height: 100px !important;
  }
 }
 @media only screen and (max-width: 400px) {
  .product-heading-img{
   height: 70px !important;
  }
 }
 .product-card {
  position: relative;
  overflow: hidden;
}

/*  */

.custom-Btn{
  background-color: #FF9D04;
}



 .image-style-align-center {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

 .image-style-align-left {
  float: left;
  margin-right: 1em;
}

 .image-style-align-right {
  float: right;
  margin-left: 1em;
}

 figure.image {
  display: inline-block;
  margin: 0;
  max-width: 100%;
}

 figure.image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
.buy-now{
  z-index: 1111
}
.add-to-cart{
  z-index: 1111
}